
<template>
  <div class="body add-vehicle">
    <el-form
      :model="addForm"
      :rules="rules"
      :inline="true"
      ref="addForm"
      label-width="82px"
      label-position="left"
      class="demo-form-inline dialog-form"
    >
      <el-form-item label="字典名称：" prop="dictValue">
        <el-input
          v-model.trim="addForm.dictValue"
          placeholder="请输入字典名称"
        ></el-input>
      </el-form-item>

      <el-form-item label="字典编码：" prop="dictCode">
        <el-input
          :disabled="isEdit"
          v-model.trim="addForm.dictCode"
          placeholder="请输入字典编码"
        ></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { addDictionary, editDictionary } from '@/api/lib/api.js'

export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isEdit: false,
      addForm: {
        dictCode: '',
        dictValue: '',
        parentCode: 0
      },
      rules: {
        dictValue: [
          { required: true, message: '请输入字典名称', trigger: 'blur' }
        ],
        dictCode: [
          { required: true, message: '请输入字典编码', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    // 新增/修改车辆
    onSave() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          const data = {
            parentCode: 0,
            dictCode: this.addForm.dictCode,
            dictValue: this.addForm.dictValue
          }
          if (!this.isEdit) {
            addDictionary(data).then((res) => {
              if (res.code === 1000) {
                this.$message.success('新增成功')
                this.$emit('close')
              } else {
                this.$message.error(res.msg)
              }
            })
          } else {
            data.id = this.addForm.id
            editDictionary(data).then((res) => {
              if (res.code === 1000) {
                this.$message.success('修改成功')
                this.$emit('close')
              } else {
                this.$message.error(res.msg)
              }
            })
          }
        } else {
          return false
        }
      })
    }
  },
  created() {},
  mounted() {
    if (
      JSON.stringify(this.item) !== '{}' &&
      JSON.stringify(this.item) !== 'null'
    ) {
      this.addForm = { ...this.item }
      this.isEdit = true
    } else {
      this.isEdit = false
    }
  }
}
</script>

<style lang="scss" scoped>
.add-vehicle {
  @include themify() {
    .form-title {
      font-size: 14px;
      color: themed('n7');
      margin-bottom: 10px;
    }
    .form-title2 {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
